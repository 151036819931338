import gql from "graphql-tag/src";

export const ALL_ORDER_LIST = gql`
query ListOfOrders($first: Int, $after: String){
  meUser {  
    company {
      orders(first: $first, after: $after) {
        pageInfo {
          hasNextPage,
          hasPreviousPage
          endCursor
          startCursor
        }
        totalCount
        edges {
          node {
            id
            _id
            headquarter {
              name
            }
            user {
              name
            }
            createdAt
            price
            unitPrice
            grossPrice
            transportPrice
            transportTax
            tax
          }
        }
      }
    }
  }
}
`

export const ORDER_DETAIL = gql`
query OrderDetail($id: ID!) {
  order(id: $id) {
    _id
    headquarter {
      name
    }
    user {
      name
    }
    price
    unitPrice
    tax
    transportPrice
    transportTax
    grossPrice
    orderIdentifier
    comment
    createdAt
    items {
      product {
        code
        name
      }
      qty
      price
      unitPrice
      tax
      grossPrice
    }
  }
} 
`