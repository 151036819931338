<template>
  <div>
    <base-page-heading title="Comanda">
      <template #extra>
        <b-breadcrumb class="breadcrumb-alt">
          <b-breadcrumb-item href="javascript:void(0)">Comenzi</b-breadcrumb-item>
          <b-breadcrumb-item active v-bind:text="'Detalii - #'+order.orderIdentifier"></b-breadcrumb-item>
        </b-breadcrumb>
      </template>
    </base-page-heading>

    <div class="content">
      <!-- Full Table -->
      <base-block title="Detalii" v-bind:subtitle="'#'+order.orderIdentifier">
        <b-row class="row-deck">
          <b-col md="4" class="col-12">
            <b-button size="md" type="submit" variant="primary" class="mb-3" @click="backToList">
              <small><i class="fa fa-fw fa-arrow-left"></i> Inapoi la lista de comenzi</small>
            </b-button>
          </b-col>
        </b-row>
        <hr/>
        <b-row>
          <b-col md="4">Creat de: <span v-if="order.user" class="text-dark font-weight-bold">{{order.user.name}}</span></b-col>
          <b-col md="4">Punct de lucru: <span v-if="order.headquarter" class="text-primary font-weight-bold">{{order.headquarter.name}}</span></b-col>
          <b-col md="4">Data: <span class="font-weight-bold">{{orderDate}}</span></b-col>
        </b-row>
        <hr/>
        <h3 class="block-title mb-4">Articole</h3>
        <b-table-simple v-if="order.items" responsive bordered striped table-class="table-vcenter">
          <b-thead head-variant="dark">
            <b-tr>
              <b-th class="d-sm-none" style="width: 100%;">Articole</b-th>
              <b-th class="d-none d-sm-table-cell" style="width: 15%;">Cod Produs</b-th>
              <b-th class="d-none d-sm-table-cell" style="width: 25%;">Denumire produs</b-th>
              <b-th class="d-none d-sm-table-cell text-center" style="width: 21%">Cant.</b-th>
              <b-th class="d-none d-sm-table-cell text-center" style="width: 15%;">Pret unitar</b-th>
              <b-th class="d-none d-sm-table-cell text-center" style="width: 14%;">Valoare</b-th>
              <b-th class="d-none d-sm-table-cell text-center" style="width: 10%;">TVA</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-for="item in order.items" :key="item._id">
              <b-td class="d-sm-none">
                <div>
                  <span class="text-dark font-weight-bolder">{{ item.product.code }}</span>
                  <span class="ml-3"> - </span>
                  <span class="text-primary ml-3">{{ item.product.name }}</span>
                </div>
                <b-row class="font-size-sm">
                  <b-col class="col-4 mt-2">
                    <div><i class="fa fa-piggy-bank"></i> <span class="text-info">{{ item.unitPrice.toFixed(2) }} <span>RON</span></span></div>
                  </b-col>
                  <b-col class="col-4 mt-2">
                    <div><i class="fa fa-dollar-sign"></i> <span>{{ item.price.toFixed(2) }} <span>RON</span></span></div>
                  </b-col>
                  <b-col class="col-4 mt-2">
                    <div><i class="fa fa-percent"></i> <span class="text-warning">{{ item.tax.toFixed(2) }} <span>RON</span></span></div>
                  </b-col>
                </b-row>
                <b-row class="mt-3">
                  <b-col class="offset-4 col-4">
                    Cantitate: 1
                  </b-col>
                </b-row>
              </b-td>
              <b-td class="d-none d-sm-table-cell font-w600">
                <span class="text-dark font-weight-bolder">{{ item.product.code }}</span>
              </b-td>
              <b-td class="d-none d-sm-table-cell">
                <span class="text-primary">{{ item.product.name }}</span>
              </b-td>
              <b-td class="d-none d-sm-table-cell text-center">
                {{item.qty}}
              </b-td>
              <b-td class="d-none d-sm-table-cell text-center">
                <span class="text-muted">{{item.unitPrice.toFixed(2)}} RON</span>
              </b-td>
              <b-td class="d-none d-sm-table-cell text-center">
                <span class="text-primary">{{item.price.toFixed(2)}} RON</span>
              </b-td>
              <b-td class="d-none d-sm-table-cell text-center border-left-0">
                <span class="text-warning">{{item.tax.toFixed(2)}} RON</span>
              </b-td>
            </b-tr>
            <b-tr v-if="order.transportPrice">
              <b-td class="d-sm-none">
                <b-row class="font-size-sm">
                  <b-col class="col-4">
                    <span class="text-info font-weight-bolder">Transport</span>
                  </b-col>
                  <b-col class="col-4">
                    <div><i class="fa fa-dollar-sign"></i> <span class="text-info font-w600">{{ order.transportPrice.toFixed(2) }} <span>RON</span></span></div>
                  </b-col>
                  <b-col class="col-4">
                    <div><i class="fa fa-percent"></i> <span class="text-info font-w600">{{ order.transportTax.toFixed(2) }} <span>RON</span></span></div>
                  </b-col>
                </b-row>
              </b-td>
              <b-td class="d-none d-sm-table-cell font-size-md text-right font-w600" colspan="4">
                <span class="info text-info">Cheltuieli transport</span>
              </b-td>
              <b-td class="d-none d-sm-table-cell font-size-md text-center font-w600">
                <span class="text-info">{{ order.transportPrice.toFixed(2) }} RON</span>
              </b-td>
              <b-td class="d-none d-sm-table-cell font-size-md text-center font-w600">
                <span class="text-info">{{ order.transportTax.toFixed(2) }} RON</span>
              </b-td>
            </b-tr>
            <b-tr v-if="order.price">
              <b-td class="d-sm-none">
                <b-row class="font-size-sm">
                  <b-col class="col-4">
                    <span class="text-dark font-weight-bolder">Total</span>
                  </b-col>
                  <b-col class="col-4">
                    <div><i class="fa fa-dollar-sign"></i> <span class="text-dark font-w600">{{ order.price.toFixed(2) }} <span>RON</span></span></div>
                  </b-col>
                  <b-col class="col-4">
                    <div><i class="fa fa-percent"></i> <span class="text-dark font-w600">{{ order.tax.toFixed(2) }} <span>RON</span></span></div>
                  </b-col>
                </b-row>
              </b-td>
              <b-td class="d-none d-sm-table-cell text-right font-w600 font-size-md" colspan="4">
                <span class="info text-dark">Total</span>
              </b-td>
              <b-td class="d-none d-sm-table-cell font-w600 font-size-md text-center">
                <span class="text-dark">{{ order.price.toFixed(2) }} RON</span>
              </b-td>
              <b-td class="d-none d-sm-table-cell font-w600 font-size-md text-center">
                <span class="text-dark">{{ order.tax.toFixed(2) }} RON</span>
              </b-td>
            </b-tr>
            <b-tr v-if="order.price">
              <b-td class="d-sm-none">
                <b-row class="font-size-h4 font-w600 text-primary">
                  <b-col class="col-6">
                    <span class="text-primary font-size-h4 font-w600">Total de plata</span>
                  </b-col>
                  <b-col class="col-6">
                    <div class="text-right"><span>{{ (order.grossPrice + order.transportTax + order.transportPrice).toFixed(2) }} <span>RON</span></span></div>
                  </b-col>
                </b-row>
              </b-td>
              <b-td class="d-none d-sm-table-cell text-right font-w600 font-size-lg" colspan="4">
                <span class="text-primary">Total de plata</span>
              </b-td>
              <b-td class="d-none d-sm-table-cell font-w600 font-size-lg text-center" colspan="2">
                <span class="text-primary">{{ (order.grossPrice + order.transportTax + order.transportPrice).toFixed(2) }} RON</span>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
        <b-row class="mt-4 mb-4">
          <b-col class="col-12">
            Comentariu: <span v-if="order.comment">{{order.comment}}</span>
          </b-col>
        </b-row>
        <hr/>
        <b-row class="row-deck mb-3">
          <b-col md="4" class="mt-3">
            <b-button size="md" type="submit" variant="primary" @click="backToList">
              <small><i class="fa fa-fw fa-arrow-left"></i> Inapoi la lista de comenzi</small>
            </b-button>
          </b-col>
        </b-row>
      </base-block>
    </div>
  </div>
</template>

<script>
import {ORDER_DETAIL} from "../../../constants/order-graphql";
import {getDateString} from "../../../constants/settings";

export default {
  name: "OrderDetail",
  data () {
    return {
      orderId: atob(this.$route.params.id),
      order: {},
      dataLoad: true,
    }
  },
  computed: {
    orderDate() {
      return getDateString(this.order.createdAt)
    }
  },
  apollo: {
    order: {
      query: ORDER_DETAIL,
      fetchPolicy: "no-cache",
      variables () {
        return {
          id: this.orderId,
        }
      },
      result(result) {
        this.order = result.data.order;
        this.dataLoad = false;
      }
    }
  },
  methods: {
    backToList() {
      this.hasHistory() ?
          this.$router.go(-1) :
          this.$router.push('/order/list')
      ;
    },
    hasHistory() {
      return window.history.length > 2
    }
  }
}
</script>

<style scoped>

</style>